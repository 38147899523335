import axios from 'axios'
import { eraseCookie, getCookie, parseJwt } from 'utils'

export function setUserFromToken(setUser) {
  const token = new UserService().getAccessToken();
  const userData = parseJwt(token);
  const user = {
    name: decodeUTF8(userData?.name || `${userData?.given_name} ${userData?.family_name}`),
    firstName: decodeUTF8(userData?.given_name),
    lastName: decodeUTF8(userData?.family_name),
    email: userData?.email,
    language: userData?.custom?.language,
    roles: userData?.resource_access['buster-client']?.roles || [],
    access_token: new UserService().getAccessToken(),
    refresh_token: new UserService().getRefreshToken(),
  }
  new UserService().setUser(user)
  setUser && setUser(user)
}

function decodeUTF8(s) {
  return decodeURIComponent(escape(s))
}

class UserService {
  hasRole(role) {
    const user = JSON.parse(localStorage.getItem('bai-user'))
    return user?.roles?.includes(role)
  }

  getRefreshToken() {
    const user = JSON.parse(localStorage.getItem('bai-user'))
    return getCookie('bi_refresh_token') || user?.refresh_token;
  }

  getAccessToken() {
    const user = JSON.parse(localStorage.getItem('bai-user'))
    return getCookie('bi_access_token') || user?.access_token;
  }

  updateAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('bai-user'))
    user.access_token = token
    eraseCookie('bi_access_token', process.env.REACT_APP_MAIN_DOMAIN)
    document.cookie = `bi_access_token=${token};domain=${process.env.REACT_APP_MAIN_DOMAIN}`
    localStorage.setItem('bai-user', JSON.stringify(user))
  }

  updateRefreshToken(token) {
    let user = JSON.parse(localStorage.getItem('bai-user'))
    user.refresh_token = token
    eraseCookie('bi_refresh_token', process.env.REACT_APP_MAIN_DOMAIN)
    document.cookie = `bi_refresh_token=${token};domain=${process.env.REACT_APP_MAIN_DOMAIN}`
    localStorage.setItem('bai-user', JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem('bai-user'))
  }

  getUserLang() {
    const parsedToken = parseJwt(this.getAccessToken());
    const language = parsedToken?.custom?.language || parsedToken?.custom?.country;
    return language ? (language === 'fr' ? 'fr' : 'en'): null ;
  }

  setUser(user) {
    localStorage.setItem('bai-user', JSON.stringify(user))
  }

  removeUser() {
    localStorage.removeItem('bai-user')
  }

  refresh(refreshToken, onFailure = null, onSuccess = null) {
    axios.create({
      headers: { Authorization: 'Bearer ' + this.getAccessToken() },
      })
      .post(process.env.REACT_APP_MIFTAH_API_URI + 'auth/refresh', {
        refreshToken,
      })
      .then((response) => {
        const token = response.data
        if (token) {
          this.updateAccessToken(token.accessToken)
          this.updateRefreshToken(token.refreshToken)
        }
        onSuccess?.()
      })
      .catch((error) => {
        console.error(error.response)
        onFailure?.()
      })
  }
}

export default new UserService()
