import Alert from 'app/components/AlertComponent'
import ToastSuccess from 'app/components/ToastSuccess'
import { User } from 'app/services/User'
import UserService from 'app/services/UserService'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { axiosInstance } from 'utils'
import ChangePasswordModal from '../Modal/ChangePassword'
import UpdateEmailModal from '../Modal/UpdateEmail'

type SecurityProps = {
  user: Pick<User, 'email'>
}

function Security({ user }: SecurityProps) {
  const location = useLocation<{ noRole: boolean; oldEmail: string }>()
  const history = useHistory()
  const { t } = useTranslation()

  const [isOpenUpdateEmail, setIsOpenUpdateEmail] = useState(false)
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false)

  const [pendingEmailUpdate, setPendingEmailUpdate] = useState<{ newEmail?: string; createdDate?: string }>({})

  useEffect(() => {
    user && checkPendingEmailUpdate()
    user && checkEmailUpdated()
  }, [user])

  const checkPendingEmailUpdate = () => {
    axiosInstance()
      .get(process.env.REACT_APP_MIFTAH_API_URI + 'user/info/read')
      .then((res) => {
        setPendingEmailUpdate(res?.data?.pendingEmailUpdate)
      })
      .catch((error) => {
        console.error(error) //TODO add error toast if needed
      })
  }

  const checkEmailUpdated = () => {
    const oldEmail = location?.state?.oldEmail
    const isUpdated = oldEmail && oldEmail !== user?.email
    const noRole = location?.state?.noRole
    history.replace({})
    isUpdated &&
      toast(
        <ToastSuccess
          message={t('accountSettings.toast.changedEmail.title')}
          desc={
            <>
              {t('accountSettings.toast.changedEmail.desc')}
              <b>{user?.email}</b>.
            </>
          }
        />
      )
    noRole &&
      toast(
        <ToastSuccess
          error={true}
          message={t('accountSettings.toast.changedEmailNoRole.title')}
          desc={<>{t('accountSettings.toast.changedEmailNoRole.desc')}</>}
        />,
        { type: 'error' }
      )
  }

  const cancelUpdateEmail = () => {
    axiosInstance()
      .put(process.env.REACT_APP_API_V2_URI + 'auth/update-email/revoke')
      .then(() => {
        setPendingEmailUpdate({})
        toast(
          <ToastSuccess
            message={t('accountSettings.toast.changedEmailCanceled.title')}
            desc={
              <>
                {t('accountSettings.toast.changedEmailCanceled.desc')}
                <b>{user?.email}</b>.
              </>
            }
          />
        )
      })
      .catch((error) => {
        console.error(error) //TODO add error toast if needed
      })
  }

  const formatDate = (date: string) => {
    return date && format(new Date(date + 'Z'), "MMMM do yyyy 'at' h:mm aaaa")
  }
  return (
    <div className="account-container m-5">
      <div className="title mb-5">{t('accountSettings.title')}</div>
      <div className="form-container">
        <div className="form-item d-flex justify-content-between flex-wrap mt-5">
          <div className="input-container">
            <label className="mb-2">{t('accountSettings.loginEmail')}</label>
            <div className="desc">{t('accountSettings.loginEmailDesc')}</div>
            {!UserService.hasRole('USER_EMAIL_UPDATE') && (
              <div className="no_access mt-1">{t('accountSettings.accountManagedByAdministration')}</div>
            )}
            <input className="mt-3" placeholder={user?.email} disabled />
          </div>
          <div className="break mb-3 d-block d-md-none"></div>
          <div className="my-auto">
            <button
              className="btn-action"
              disabled={!UserService.hasRole('USER_EMAIL_UPDATE') || !!pendingEmailUpdate}
              onClick={() => setIsOpenUpdateEmail(true)}
            >
              {t('common.update')}
            </button>
          </div>
        </div>
        {pendingEmailUpdate?.newEmail && (
          <Alert
            title={t('accountSettings.emailUpdateAlert.title')}
            desc={
              <Trans
                i18nKey="accountSettings.emailUpdateAlert.desc"
                values={{
                  email: pendingEmailUpdate?.newEmail,
                  date: pendingEmailUpdate?.createdDate && formatDate(pendingEmailUpdate?.createdDate),
                }}
                components={{ bold: <b /> }}
              />
            }
            action={
              <button className="alert-action p-0" onClick={cancelUpdateEmail}>
                {t('accountSettings.emailUpdateAlert.action')}
              </button>
            }
          />
        )}
        <div className="form-item d-flex justify-content-between flex-wrap mt-5">
          <div className="input-container">
            <label className="mb-2">{t('accountSettings.password')}</label>
            <div className="desc mb-3">{t('accountSettings.changeAccountPassword')}</div>
          </div>
          <div className="break mb-0 mb-md-3 d-block d-md-none"></div>
          <div className="my-auto">
            <button
              disabled={!UserService.hasRole('USER_INFO_UPDATE')}
              className="btn-action"
              onClick={() => setIsOpenChangePassword(true)}
            >
              {t('accountSettings.changeYourPassword')}
            </button>
          </div>
        </div>
      </div>
      <ChangePasswordModal show={isOpenChangePassword} email={user?.email} callback={setIsOpenChangePassword} />
      <UpdateEmailModal
        state={isOpenUpdateEmail}
        action={setIsOpenUpdateEmail}
        checkPendingEmailUpdate={checkPendingEmailUpdate}
      />
    </div>
  )
}

export default Security
