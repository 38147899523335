import Sidebar from 'app/components/Sidebar'
import TopSidebar from 'app/components/TopSidebar'
import UserService, { setUserFromToken } from 'app/services/UserService'
import close from 'assets/img/picto/icon-close--gray-4.svg'
import React, { useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { getCookie, setCookie } from 'utils'
import { detectAndUpdateLanguage } from '../../../utils'
import './Home.scss'
import Preference from './Preference'
import Profile from './Profile'
import Security from './Security'

function Home() {
  const [user, setUser] = useState(null)
  const [showSideBar, setShowSideBar] = useState(true)
  let history = useHistory()
  const node = useRef()

  useEffect(() => {
    // get user if exist or redirect to signin page
    let user = UserService.getUser()
    if (user && user.refresh_token) {
      setUser(user)
    } else if (getCookie('bi_access_token')) {
      setUserFromToken(setUser)
    } else {
      setCookie('bi_redirect_uri', document.URL)
      history.push('/signin')
    }
    // check the user has the good token else logout
    UserService.refresh(UserService.getRefreshToken(), () => history.push('/signin'))
  }, [])

  // to handel header toggle on mobile layout
  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  // detect user languague 
  useEffect(() => {
    detectAndUpdateLanguage(user?.language);
  }, [user?.language])

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    setShowSideBar(true)
  }

  return (
    <div className="d-flex">
      {!showSideBar && <div className="dark-container" />}
      <div ref={node}>
        <Sidebar user={user} show={showSideBar} />
      </div>
      <div className="flex-grow-1 bg-white">
        <TopSidebar toggle={() => setShowSideBar(!showSideBar)} />
        <Switch>
          <Route exact path="/">
            <Profile user={user} setUser={setUser} />
          </Route>
          <Route exact path="/preferences">
            <Preference user={user} setUser={setUser} />
          </Route>
          <Route exact path="/security">
            <Security user={user} />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={({ closeToast }) => (
          <div className="close mt-2">
            <img src={close} onClick={closeToast} />
          </div>
        )}
      />
    </div>
  )
}

export default Home
