import React, { useRef, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import './Landing.scss'
import logo from 'assets/img/logo_buster_ai_trademark.svg'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Error from 'app/components/Error'
import axios from 'axios'

function ForgotPassword() {
  const location = useLocation()
  const [error, setError] = useState(null)
  const history = useHistory()
  const navigateTo = (email) =>
    history.push({
      pathname: '/password_reset/verify_email',
      state: { email: email },
    })

  const formRef = useRef()

  const forgotPwdSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email address.').required('Please enter your email'),
  })

  const onSubmit = (values) => {
    setError(null)
    axios
      .post(process.env.REACT_APP_API_V2_URI + 'auth/forgot-password?email=' + values.email)
      .then(() => {
        navigateTo(values.email)
      })
      .catch((error) => {
        console.error(error.response)
        setError(error?.response?.data?.message)
      })
  }

  return (
    <div className="container-fluid full-page-container">
      <div className="row h-100 justify-content-center">
        <div className="main-card px-4 px-sm-0">
          <div className="py-4">
            <img src={logo} height="50" alt="Buster AI" />
            {location?.state?.errorToken && (
              <Error
                message="The link to reset your password expired. Please try again."
                style={{
                  marginTop: '72px',
                  marginBottom: '-40px',
                  width: '472px',
                  maxWidth: '80vw',
                }}
              />
            )}
            <h5>
              <strong>Forgot your password?</strong>
            </h5>
            <div className="desc">
              Enter the email address you used when you joined and we’ll send you instructions to reset your password.
            </div>
          </div>
          <Formik
            initialValues={{
              email: '',
            }}
            innerRef={formRef}
            validationSchema={forgotPwdSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-input">
                  <label htmlFor="username">Email</label>
                  <Field placeholder="Email" type="text" name="email" id="username" />
                  {(errors.email && touched.email) || error ? <div className="message">{errors.email}</div> : null}
                </div>
                <button className="my-4 button primary simple ms-0" type="submit">
                  Send reset instructions
                </button>
              </Form>
            )}
          </Formik>
          <div className="remember d-flex mt-4">
            <div className="d-none d-sm-block">Remember your password?</div>
            <Link to="/signin">
              <div className="cta-blue ms-sm-2 ms-xs-0">Return to login page</div>
            </Link>
          </div>
        </div>
        <div className="background"></div>
      </div>
    </div>
  )
}

export default ForgotPassword
