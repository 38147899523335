import ToastSuccess from 'app/components/ToastSuccess'
import close from 'assets/img/picto/icon-close--gray-4.svg'
import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Modal from 'simple-react-modal'
import { axiosInstance } from 'utils'
import { toast } from 'react-toastify'
import './ChangePassword.scss'

type ChangePasswordModalProps = {
  show: boolean
  email?: string
  callback(value: boolean): void
}

function ChangePasswordModal({ show, email, callback }: ChangePasswordModalProps) {
  const { t } = useTranslation()
  const [pendingChangePassword, setPendingChangePassword] = useState<boolean>(false)

  const requestChangePassword = () => {
    setPendingChangePassword(true)
    axiosInstance()
      .post(process.env.REACT_APP_MIFTAH_API_URI + 'user/password/update', {})
      .then(() => {
        toast(
          <ToastSuccess
            message={t('accountSettings.toast.changedPassword.title')}
            desc={
              <Trans
                i18nKey="accountSettings.toast.changedPassword.desc"
                values={{
                  email,
                }}
                components={{ bold: <b /> }}
              />
            }
          />
        )
        closeModal();
      })
      .catch((error) => {
        console.error(error.response) // TODO add error toast
      })
      .finally(() => setPendingChangePassword(false))
  }

  const closeModal = () => {
    callback(false)
  }
  return (
    <Modal
      show={show}
      containerClassName="bai-modal change-password"
      onClose={closeModal}
      closeOnOuterClick={false}
      style={{ width: '100%', background: '#8D8AA070' }}
    >
      <div className="modal-dialog modal-change-pwd" role="document">
        <div className="modal-content mx-3 border-0">
          <div className="modal-header border-0 mt-3 me-md-2">
            <h5 className="modal-title mt-0">{t('modal.changePassword.title')}</h5>
            <div className="close" role="button" onClick={closeModal}>
              <img src={close} />
            </div>
          </div>
          <div className="modal-body">
            <Trans i18nKey="modal.changePassword.description" components={{ bold: <b /> }} />
          </div>
          <div className="modal-footer border-0 mb-3 me-md-2">
            <a type="button" className="button simple" onClick={closeModal}>
              {t('common.cancel')}
            </a>
            <button
              className="button primary simple ms-0"
              disabled={pendingChangePassword}
              onClick={requestChangePassword}
            >
              {t('common.confirm')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChangePasswordModal
