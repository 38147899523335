import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import TRANSLATIONS_EN from "./en.json";
import TRANSLATIONS_FR from "./fr.json";
 

export const defaultNS = 'en'
export const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  fr: {
    translation: TRANSLATIONS_FR,
  }
};

i18n
 .use(initReactI18next)
 .init({
   fallbackLng: "en",
   resources: resources,
 });
 i18n.changeLanguage("en");

 