import logo from 'assets/img/logo_buster_ai_trademark.svg'
import logoutIcon from 'assets/img/picto/icon-logout--gray-2.svg'
import prefernceBlueSVG from 'assets/img/picto/icon-preference-blue.svg'
import prefernceSVG from 'assets/img/picto/icon-preference.svg'
import securityBlueSVG from 'assets/img/picto/icon-security-blue.svg'
import securitySVG from 'assets/img/picto/icon-security.svg'
import userBlueSVG from 'assets/img/picto/icon-user-profile--electric-blue.svg'
import userSVG from 'assets/img/picto/icon-user-profile.svg'
import backSVG from 'assets/img/picto/icon-return.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import './Sidebar.scss'
import { backToApp } from 'utils'

type Props = {
  show: boolean
  user: { name: string; email: string }
}

const logout = () => {
  window.open(
    `${process.env.REACT_APP_MIFTAH_API_URI}auth/logout?redirect_uri=${window.location.origin}/signin`,
    '_self'
  )
}

const Sidebar = ({ show, user }: Props) => {
  const { t } = useTranslation()
  return (
    <div className={show ? 'sidebar-container  d-none d-md-block' : 'sidebar-container active bg-white'}>
      <div className="sidebar-row ms-3 mx-md-5 position-relative h-100">
        <div className="mb-5">
          <a onClick={backToApp}><img src={logo} height="30" alt="Buster AI" /></a>
        </div>
        <div>
          <div className="username">{user?.name}</div>
          <div className="email mb-4">{user?.email}</div>
        </div>
        <hr />
        <div className="back-to-app"><a onClick={backToApp}>
          <img src={backSVG} className="me-2" />
          {t('sidebar.backToApp')}</a>
          </div>
        <hr />
        <div className="menu">
          <h3>{t('sidebar.setting')}</h3>
          <NavLink activeClassName="active" exact className="menu-item d-flex" to="/">
            <img src={userSVG} className="me-2" />
            <img src={userBlueSVG} className="me-2" />
            {t('sidebar.profile')}
          </NavLink>
          <NavLink activeClassName="active" exact className="menu-item d-flex mt-1" to="/preferences">
            <img src={prefernceSVG} className="me-2" />
            <img src={prefernceBlueSVG} className="me-2" />
            {t('sidebar.preferences')}
          </NavLink>
          <NavLink activeClassName="active" exact className="menu-item d-flex mt-1" to="/security">
            <img src={securitySVG} className="me-2" />
            <img src={securityBlueSVG} className="me-2" /> {t('sidebar.security')}
          </NavLink>
        </div>
        <div className="col-12 position-absolute bottom-0 mb-5">
          <hr />
          <div className="sign-out mt-3 f-flex" onClick={logout}>
            <img src={logoutIcon} className="me-2" />
            {t('sidebar.signout')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
