import React, { useRef, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import './Landing.scss'
import logo from 'assets/img/logo_buster_ai_trademark.svg'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import ShowPassword from 'app/components/ShowPassword'

function ResetPassword() {
  const [error, setError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const [showPasswordConfirmed, setShowPasswordConfirmed] = useState(false)
  const toggleShowPasswordConfirmed = () => setShowPasswordConfirmed(!showPasswordConfirmed)

  const history = useHistory()
  const navigateTo = (success) =>
    success
      ? history.push({
          pathname: '/password_reset/success',
        })
      : history.push({
          pathname: '/password_reset/new',
          state: { errorToken: true },
        })

  const formRef = useRef()

  const resetPwdSchema = Yup.object().shape({
    newPassword: Yup.string()
      .matches(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#:$%^&*])(?=.{8,})'), 'regex')
      .required('Please type a new password.'),
    newPasswordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'These passwords don’t match. Please try again.')
      .when('newPassword', {
        is: (newPassword) => newPassword?.length > 0,
        then: Yup.string().required('Please confirm your password.'),
      }),
  })

  const onSubmit = (values) => {
    setError(null)
    const search = window.location.search
    const token = search.split('token=') && search.split('token=')[1]
    axios
      .put(process.env.REACT_APP_API_V2_URI + 'auth/set-password', {
        newPassword: values.newPassword,
        token: token,
      })
      .then(() => {
        navigateTo(true)
      })
      .catch((error) => {
        console.error(error.response)
        setError(error?.response?.data?.message)
        if (error?.response?.status === 401) {
          navigateTo(false)
        }
      })
  }

  return (
    <div className="container-fluid full-page-container">
      <div className="row h-100 justify-content-center">
        <div className="main-card px-4 px-sm-0">
          <div className="py-4">
            <img src={logo} height="50" alt="Buster AI" />
            <h5>
              <strong>Reset your password</strong>
            </h5>
            <div className="desc">Set up a new password to your Buster.Ai account.</div>
          </div>
          <Formik
            initialValues={{
              newPassword: '',
              newPasswordConfirm: '',
            }}
            innerRef={formRef}
            validationSchema={resetPwdSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className="form-input">
                  <label htmlFor="newPassword">New password</label>
                  <Field
                    placeholder="New password"
                    type={showPassword ? 'text' : 'password'}
                    name="newPassword"
                    className={errors.newPassword && touched.newPassword ? 'password error' : 'password'}
                  />
                  <ShowPassword showPassword={showPassword} action={toggleShowPassword} />
                  <span
                    className={
                      errors.newPassword && touched.newPassword && errors.newPassword === 'regex' ? 'message' : ''
                    }
                  >
                    Your password must contain between 8 and 40 characters, a number, uppercase and lowercase letters
                    and a special character.
                  </span>
                </div>
                <div className="form-input">
                  <label htmlFor="newPasswordConfirm">Confirm new password</label>
                  <Field
                    placeholder="Confirm new password"
                    type={showPasswordConfirmed ? 'text' : 'password'}
                    name="newPasswordConfirm"
                    className={errors.newPasswordConfirm && touched.newPasswordConfirm ? 'password error' : 'password'}
                  />
                  <ShowPassword showPassword={showPasswordConfirmed} action={toggleShowPasswordConfirmed} />
                  {(errors.newPasswordConfirm && touched.newPasswordConfirm) || error ? (
                    <div className="message">{errors.newPasswordConfirm}</div>
                  ) : null}
                </div>
                <div className="button-container">
                  {(values.newPasswordConfirm === '' &&
                    values.newPassword === '' &&
                    touched.newPassword &&
                    touched.newPasswordConfirm) ||
                  error ? (
                    <div className="message">{error || 'Please type a new password.'}</div>
                  ) : null}
                  <button className="button primary simple ms-0" type="submit">
                    Reset my password
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="background"></div>
      </div>
    </div>
  )
}

export default ResetPassword
