import React from 'react'
import alertIcon from 'assets/img/picto/status-icon--success--with-border.svg'
import alertIconError from 'assets/img/picto/status-icon--error--red--with-border.svg'

type Props = {
  error?: boolean,
  message: string,
  desc?: React.ReactNode | string,
}

const ToastSuccess = ({error = false, message, desc}: Props) => (
    <div className={`toast-success-container ${error ? 'error' : 'info'}`}>
      <img src={error ? alertIconError : alertIcon} />
      <div>
        {message}
        {desc && <div className="toast-desc"> {desc}</div>}
      </div>
    </div>
  )

export default ToastSuccess
