import Auth from './app/container/Auth'
import ForgotPassword from './app/container/ForgotPassword/Landing'
import ForgotPasswordSuccess from './app/container/ForgotPassword/Success'
import Home from './app/container/Home'
import ResetPassword from './app/container/ResetPassword/Landing'
import ResetPasswordSuccess from './app/container/ResetPassword/Success'
import ErrorLink from './app/container/UpdateEmailGuard/Error'
import UpdateEmailGuard from './app/container/UpdateEmailGuard/Guard'

const routes = [
  {
    path: '/signin',
    component: Auth,
  },
  {
    path: '/email_update/confirm',
    component: UpdateEmailGuard,
  },
  {
    path: '/email_update/error',
    component: ErrorLink,
  },
  {
    path: '/password_reset/new',
    component: ForgotPassword,
  },
  {
    path: '/password_reset/verify_email',
    component: ForgotPasswordSuccess,
  },
  {
    path: '/password_reset/edit',
    component: ResetPassword,
  },
  {
    path: '/password_reset/success',
    component: ResetPasswordSuccess,
  },
  {
    path: '/',
    component: Home,
  },
]

export default routes
