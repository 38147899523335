import React from 'react'
import './ShowPassword.scss'
import eye from 'assets/img/picto/icon-eye-shown.svg'
import eyeHidden from 'assets/img/picto/icon-eye-hidden.svg'

type Props = {
  showPassword: boolean,
  action: () => void,
}

const ShowPassword = ({showPassword, action}: Props) => (
    <>
      {!showPassword && <img src={eye} onClick={action} className="showPassword" />}
      {showPassword && <img src={eyeHidden} onClick={action} className="showPassword" />}
    </>
)

export default ShowPassword
