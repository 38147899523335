import ToastSuccess from 'app/components/ToastSuccess'
import UserService, { setUserFromToken } from 'app/services/UserService'
import close from 'assets/img/picto/icon-close--gray-4.svg'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Modal from 'simple-react-modal'
import { axiosInstance } from 'utils'
import * as Yup from 'yup'



function UpdateNameModal(props) {
  const { t } = useTranslation();
  const updateNameSchema = Yup.object().shape({
    firstName: Yup.string()
    .required(t("modal.updateName.messages.enterFirstName"))
    .max(25, t("modal.updateName.messages.enterShorterFirstName"))
    .matches(new RegExp("^([a-zA-ZÀ-ÿ'-]+ )*[a-zA-ZÀ-ÿ'-]+$"), t("modal.updateName.messages.enterValidFirstName")),
    lastName: Yup.string()
    .required(t("modal.updateName.messages.enterLastName"))
    .max(25, t("modal.updateName.messages.enterShorterLastName"))
    .matches(new RegExp("^([a-zA-ZÀ-ÿ'-]+ )*[a-zA-ZÀ-ÿ'-]+$"), t("modal.updateName.messages.enterValidLastName"))
    ,
  })

  const onSubmit = (values, { setSubmitting }) => {
    axiosInstance()
      .patch(process.env.REACT_APP_MIFTAH_API_URI + 'user/info/update', {
        firstName: values.firstName,
        lastName: values.lastName,
      })
      .then(() => {
        setSubmitting(false)
        UserService.refresh(UserService.getRefreshToken(), null, () => setUserFromToken(props?.setUser))
        toast(<ToastSuccess message={t("modal.updateName.messages.success")} />)
        props?.action(false)
      })
      .catch(() => {
        //TODO add error toast
      })
  }
  return (
    <Modal
      show={props?.state}
      containerClassName="bai-modal update-name"
      onClose={() => props?.action(false)}
      closeOnOuterClick={false}
      style={{ width: '100%', background: '#8D8AA070' }}
    >
      <div className="modal-dialog modal-change-pwd" role="document">
        <div className="modal-content px-3 border-0">
          <div className="modal-header border-0 mt-3">
            <h5 className="modal-title mt-0">{t("modal.updateName.title")}</h5>
            <div className="close" role="button" onClick={() => props?.action(false)}>
              <img src={close} />
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: props?.user?.firstName,
              lastName: props?.user?.lastName,
            }}
            validationSchema={updateNameSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="col-12">
                <div className="modal-body d-flex">
                  <div className="col-12">
                    <div className="col-12">
                      <div className="form-input mt-0">
                        <label htmlFor="firstName">{t("modal.updateName.firstName")}</label>
                        <Field
                          placeholder={t("modal.updateName.firstName")}
                          type="text"
                          name="firstName"
                          className={errors.firstName && touched.firstName ? 'error' : ''}
                        />
                        {errors.firstName && <div className="message">{errors.firstName}</div>}
                      </div>
                      <div className="form-input mt-0">
                        <label htmlFor="lastName">{t("modal.updateName.lastName")}</label>
                        <Field
                          placeholder={t("modal.updateName.lastName")}
                          type="text"
                          name="lastName"
                          className={errors.lastName && touched.lastName ? 'error' : ''}
                        />
                        {errors.lastName && <div className="message">{errors.lastName}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 mb-3">
                  <a type="button" className="button simple" onClick={() => props?.action(false)}>
                  {t("common.cancel")}
                  </a>
                  <button className="button primary simple ms-0" disabled={isSubmitting}>
                  {t("common.update")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateNameModal
