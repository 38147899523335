import ShowPassword from 'app/components/ShowPassword'
import ToastSuccess from 'app/components/ToastSuccess'
import close from 'assets/img/picto/icon-close--gray-4.svg'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Modal from 'simple-react-modal'
import { axiosInstance } from 'utils'
import * as Yup from 'yup'


function UpdateEmailModal(props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false)

  const updateNameSchema = Yup.object().shape({
    newEmail: Yup.string().required(t("modal.updateEmail.messages.enterValidEmail")),
    password: Yup.string().required(t("modal.updateEmail.messages.enterPassword")),
  })

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    axiosInstance()
      .post(process.env.REACT_APP_API_V2_URI + 'auth/update-email/request', {
        newEmail: values.newEmail,
        password: values.password,
      })
      .then(() => {
        setSubmitting(false)
        toast(
          <ToastSuccess
            message={t("modal.updateEmail.messages.success.title")}
            desc={<>{t("modal.updateEmail.messages.success.desc")}<b>{values.newEmail}</b>. </>}
          />
        )
        onClose()
        props?.checkPendingEmailUpdate()
      })
      .catch((error) => {
        setSubmitting(false)
        const errorCode = error?.response?.data?.errorCode
        if (errorCode === 605) {
          setErrors({ newEmail: t("modal.updateEmail.messages.enterValidEmail")})
        }
        if (errorCode === 606) {
          setErrors({ newEmail: t("modal.updateEmail.messages.emailAlradyUsed")})
        }
        if (errorCode === 600) {
          setErrors({ password: t("modal.updateEmail.messages.passwordDismatch")})
        }
      })
  }

  const onClose = () => {
    setShowPassword(false)
    props?.action(false)
  }

  return (
    <Modal
      show={props?.state}
      containerClassName="bai-modal update-name"
      onClose={onClose}
      closeOnOuterClick={false}
      style={{ width: '100%', background: '#8D8AA070' }}
    >
      <div className="modal-dialog modal-change-pwd" role="document">
        <div className="modal-content px-3 border-0">
          <div className="modal-header border-0 mt-3">
            <h5 className="modal-title mt-0">{t("modal.updateEmail.title")}</h5>
            <div className="close" role="button" onClick={onClose}>
              <img src={close} />
            </div>
          </div>
          <Formik
            initialValues={{
              newEmail: '',
              password: '',
            }}
            validationSchema={updateNameSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting, isValid, dirty }) => (
              <Form className="col-12">
                <div className="modal-body d-flex">
                  <div className="col-12">
                    <div className="col-12">
                      <div className="form-input mt-0">
                        <label htmlFor="newEmail">{t("modal.updateEmail.newEmail")}</label>
                        <Field
                          placeholder={t("modal.updateEmail.newEmailPlaceholder")}
                          type="text"
                          name="newEmail"
                          className={errors.newEmail && touched.newEmail ? 'error' : ''}
                        />
                        {errors.newEmail && <div className="message">{errors.newEmail}</div>}
                      </div>
                      <div className="form-input mt-3">
                        <label htmlFor="lastName">{t("modal.updateEmail.password")}</label>
                        <Field
                          placeholder={t("modal.updateEmail.passwordPlaceholder")}
                          type={showPassword ? 'text' : 'password'}
                          autoComplete="off"
                          name="password"
                          className={
                            errors.password && touched.password ? 'error d-none d-sm-block' : 'd-none d-sm-block'
                          }
                        />
                        <Field
                          placeholder="Please confirm your password"
                          type={showPassword ? 'text' : 'password'}
                          autoComplete="off"
                          name="password"
                          className={
                            errors.password && touched.password ? 'error d-block d-sm-none' : 'd-block d-sm-none'
                          }
                        />
                        <ShowPassword showPassword={showPassword} action={() => setShowPassword(!showPassword)} />
                        {errors.password && touched.password && <div className="message">{errors.password}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 mb-3">
                  <a type="button" className="button simple" onClick={onClose}>
                  {t("common.cancel")}
                  </a>
                  <button className="button primary simple ms-0" disabled={!(isValid && dirty) || isSubmitting}>
                  {t("common.update")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateEmailModal
