import logo from 'assets/img/logo_buster_ai_trademark.svg'
import burger from 'assets/img/picto/icon-menu.svg'
import React from 'react'
import { backToApp } from 'utils'
import './TopSidebar.scss'

type Props = {
  toggle: () => void
}

const TopSidebar = ({ toggle }: Props) => (
  <div className="top-sidebar-container d-flex p-3 d-block d-md-none">
    <div className="ms-3" onClick={toggle}>
      <img src={burger} alt="Menu" />
    </div>
    <div className="w-100 text-center logo">
      <a onClick={backToApp}>
        <img src={logo} height="22" alt="Buster AI" />
      </a>
    </div>
  </div>
)

export default TopSidebar
