import React from 'react'
import logo from 'assets/img/logo_buster_ai_trademark.svg'
import brokenLink from 'assets/img/picto/broken-link.svg'
import { Link } from 'react-router-dom'
import './Error.scss'

function ErrorLink() {
  return (
    <>
      <div className="top-sidebar-container d-flex p-3 d-block d-md-none">
        <div className="w-100 text-center logo">
          <img src={logo} height="22" alt="Buster AI" />
        </div>
      </div>
      <div className="error-token-expired container-fluid full-page-container">
        <div className="w-100 p-4 d-none d-md-block">
          <img src={logo} height="30" />
        </div>
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center">
            <img className="mt-3 mb-4" src={brokenLink} />
            <div className="mt-5 title">This confirmation link has expired.</div>
            <div className="mt-3 mb-3 desc">
              Please try again or email <a href="mailto:support@buster.ai">support@buster.ai</a>.
            </div>
            <Link to="/">
              <a className="button primary simple mt-5">Go to Account Settings</a>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorLink
