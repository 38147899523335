import axios from 'axios'
import UserService from './app/services/UserService'
import i18n from 'i18next';

export function eraseCookie(name, domain) {
  const domainName = domain === 'localhost' ? domain : `.${domain}`
  document.cookie = `${name}=; path=/; domain=${domainName}; max-age=0`
}

export function getCookie(name) {
  const cookie = {}
  document.cookie.split(';').forEach((el) => {
    const [k, ...v] = el.split('=')
    cookie[k.trim()] = v.join('=')
  })
  return cookie[name]
}

export function setCookie(name, value, path = '/') {
  document.cookie = `${name}=${value};domain=${process.env.REACT_APP_MAIN_DOMAIN};path=${path}`
}

export function getUrlParam(name, url) {
  if (!url) url = location.href
  name = name.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]')
  var regexS = '[\\?&]' + name + '=([^&#]*)'
  var regex = new RegExp(regexS)
  var results = regex.exec(url)
  return results == null ? false : results[1]
}

export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function axiosInstance() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_V2_URI,
    headers: { Authorization: 'Bearer ' + UserService.getAccessToken() },
  })
  instance.interceptors.request.use((config) => {
    detectExpireAndRefreshToken();
    return config;
  }, (err) => {
     console.log("error in getting ", err)
  });
  return instance
}

export function detectAndUpdateLanguage(userLang) {
  const language = userLang ? userLang : (navigator.language || navigator.userLanguage);
  i18n.changeLanguage(language.includes('fr') ? language: 'en');
}

export function backToApp() {
  window.open(`${process.env.REACT_APP_APP_URI}`, '_self');
}

function detectExpireAndRefreshToken() {
  const parsedToken = parseJwt(UserService.getAccessToken());
  const tokenLifetime = parsedToken.exp - parsedToken.iat;
  const expireIn = parsedToken.exp - Math.floor(Date.now()/1000);
  if ((tokenLifetime / 2) > expireIn) {
    UserService.refresh(UserService.getRefreshToken());
  }
}

