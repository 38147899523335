import UserService from 'app/services/UserService'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './Input.scss'
import './locales/i18n'
import routes from './Routes'
import './Styles.scss'
import { detectAndUpdateLanguage } from './utils'

function App() {
  useEffect(() => {
    const userLang = UserService.getUserLang();
    detectAndUpdateLanguage(userLang);
  }, [])
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            render={(props) => <route.component {...props} />}
          />
        ))}
      </Switch>
    </BrowserRouter>
  )
}

export default App
