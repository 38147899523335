import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

let app = document.getElementById('root')
if (app) {
  // 1. Set up the browser history with the updated location
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1]
  if (path) {
    history.replace(path)
  }
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
