import React from 'react'
import logo from 'assets/img/logo_buster_ai_trademark.svg'
import { Link, useLocation } from 'react-router-dom'

function ForgotPasswordSuccess() {
  const location = useLocation()
  return (
    <div className="container-fluid full-page-container">
      <div className="row h-100 justify-content-center">
        <div className="main-card px-4 px-sm-0">
          <div className="py-4">
            <img src={logo} height="50" alt="Buster AI" />
            <h5>
              <strong>Coming right up!</strong>
            </h5>
            <div className="desc">
              Please check your email <b style={{ color: '#3a2d80' }}>{location?.state?.email}</b> and follow the
              instructions.
            </div>
          </div>
          <Link to="/signin">
            <div className="cta-blue mt-5">Return to login page</div>
          </Link>
        </div>
        <div className="background"></div>
      </div>
    </div>
  )
}

export default ForgotPasswordSuccess
