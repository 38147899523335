import UpdateNameModal from 'app/container/Home/Modal/UpdateName'
import { User } from 'app/services/User'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'
import './Profile.scss'

type ProfileProps = {
  user: Pick<User, 'name' | 'firstName' | 'lastName'>,
  setUser: () => void,
}

function Profile({ user, setUser }: ProfileProps) {
  const { t } = useTranslation()
  const [isOpenUpdateName, setIsOpenUpdateName] = useState(false)
  return (
    <div className="account-container profile m-5">
      <div className="title mb-5">{t('accountSettings.profile.title')}</div>
      <div className="form-container">
        <div className="form-item d-flex justify-content-between flex-wrap">
          <div>
            <label className="mb-3">{t('accountSettings.profile.label')}</label>
            <div className="input-container">
              <label className="mb-3">{t('modal.updateName.firstName')}</label>
              <input placeholder={user?.firstName} disabled />
            </div>
            <div className="input-container mt-3">
              <label className="mb-3">{t('modal.updateName.lastName')}</label>
              <input placeholder={user?.lastName} disabled />
            </div>
          </div>
          <div className="break mb-3 d-block d-md-none"></div>
          <div className="my-auto">
            <button onClick={() => setIsOpenUpdateName(true)} className="btn-action">
              {t('common.update')}
            </button>
          </div>
        </div>
        <UpdateNameModal
          state={isOpenUpdateName}
          user={user}
          action={setIsOpenUpdateName}
          setUser={setUser}
        />
      </div>
    </div>
  )
}

export default Profile
