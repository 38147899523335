import ToastSuccess from 'app/components/ToastSuccess'
import { User } from 'app/services/User'
import UserService, { setUserFromToken } from 'app/services/UserService'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { axiosInstance } from 'utils'
import './Preference.scss'

type PreferenceProps = {
  user: Pick<User, 'language'>
  setUser: () => void
}

function Preference({ user, setUser }: PreferenceProps) {
  const { t } = useTranslation()

  const updateLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value
    if (language !== user?.language) {
      axiosInstance()
        .patch(process.env.REACT_APP_MIFTAH_API_URI + 'user/info/update', { language })
        .then(() => {
          // eslint-disable-next-line
          UserService.refresh(UserService.getRefreshToken(), null, (() => setUserFromToken(setUser)) as any)
          toast(<ToastSuccess message={t('accountSettings.toast.changedLanguage.title')} />)
        })
        .catch(() => {
          //TODO add error toast
        })
    }
  }
  return (
    <div className="account-container preference m-5">
      <div className="title mb-5">{t('accountSettings.preference.title')}</div>
      <div className="form-container">
        <div className="form-item d-flex justify-content-between flex-wrap">
          <div>
            <div className="input-container">
              <label>{t('accountSettings.preference.label')}</label>
              <div className="desc mb-3 mt-2">{t('accountSettings.preference.desc')}</div>
              <select
                className="form-select"
                aria-label="Select language"
                onChange={updateLanguage}
                value={user?.language || i18next.language}
              >
                <option value="fr">Français</option>
                <option value="en">English</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Preference
