import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { axiosInstance, getUrlParam, setCookie } from 'utils'
import UserService, { setUserFromToken } from 'app/services/UserService'

function UpdateEmailGuard() {
  let history = useHistory()
  let token, oldEmail, newEmail
  useEffect(() => {
    getUrlParams()
    // redirect to signin if not authenticated
    if (!UserService.getUser()) {
      setCookie('bi_redirect_uri', document.URL)
      history.push('/signin?context=update-email', { url: document.URL, token, oldEmail, newEmail })
    }
    // process confirm
    else {
      axiosInstance()
        .put(process.env.REACT_APP_API_V2_URI + 'auth/update-email/confirm', {
          token,
        })
        .then(() => {
          // confirmation is done, refresh token and user email then redirect to account setting
          UserService.refresh(UserService.getRefreshToken(), null, () =>
            setUserFromToken(() => history.push('/', { oldEmail }))
          )
        })
        .catch((error) => {
          const errorStatus = error?.response?.status
          if (!UserService.hasRole('USER_EMAIL_UPDATE') || errorStatus === 403) {
            history.push('/', { noRole: true })
          } else {
            history.push('/email_update/error')
          }
        })
    }
  }, [])

  const getUrlParams = () => {
    token = getUrlParam('token', document.URL)
    oldEmail = getUrlParam('o', document.URL)
    newEmail = getUrlParam('n', document.URL)
  }

  return (
    <div className="d-flex h-100 w-100 position-absolute justify-content-center align-items-center">
      <div className="spinner-border" role="status" style={{ color: '#3a2d83' }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

export default UpdateEmailGuard
