import React, { useLayoutEffect } from 'react'
import axios from 'axios'
import './Auth.scss'
import { axiosInstance, eraseCookie, getCookie, getUrlParam, setCookie } from 'utils'
import { useHistory } from 'react-router-dom'
import UserService, { setUserFromToken } from 'app/services/UserService'

function Auth() {
  const history = useHistory()
  useLayoutEffect(() => {
    // delete old cookies
    eraseCookie('bi_refresh_token', process.env.REACT_APP_MAIN_DOMAIN)
    eraseCookie('bi_access_token', process.env.REACT_APP_MAIN_DOMAIN)
    UserService.removeUser()
    const authCode = getUrlParam('code', document.URL)
    if (authCode) {
      const targetApp = getCookie('bi_redirect_uri')
      getToken(authCode, targetApp)
      return
    }
    redirectToKeyclockSignIn()
  }, [])

  const redirectToKeyclockSignIn = () => {
    let loginEndpoint = `${process.env.REACT_APP_MIFTAH_API_URI}auth/login?redirect_uri=${window.location.origin}/signin`
    const email = getUrlParam('email', document.URL)
    email && (loginEndpoint += `&login_hint=${email}`)
    detectAndSaveParams()
    window.open(loginEndpoint, '_self')
  }

  const getToken = (code, targetApp) => {
    axios
      .post(process.env.REACT_APP_MIFTAH_API_URI + 'auth/token', {
        code,
        redirectUri: `${window.location.origin}/signin`,
      })
      .then(async (response) => {
        const refreshToken = response?.data?.refreshToken
        const accessToken = response?.data?.accessToken
        if (accessToken) {
          document.cookie = `bi_refresh_token=${refreshToken};domain=${process.env.REACT_APP_MAIN_DOMAIN}`
          document.cookie = `bi_access_token=${accessToken};domain=${process.env.REACT_APP_MAIN_DOMAIN}`
          eraseCookie('bi_redirect_uri', process.env.REACT_APP_MAIN_DOMAIN)
          setUserFromToken()
          await checkFisrtLoginAndSendAnalytics()
          if (targetApp) {
            window.open(targetApp, '_self')
          } else {
            history.push('/')
          }
        }
      })
      .catch((error) => {
        console.error(error.response)
      })
  }

  const detectAndSaveParams = () => {
    const [firstLogin, redirectUri] = [getUrlParam('firstLogin', document.URL), getUrlParam('continue', document.URL)]
    firstLogin && setCookie('bi_first_login', firstLogin)
    redirectUri && setCookie('bi_redirect_uri', redirectUri)
  }

  const checkFisrtLoginAndSendAnalytics = async () => {
    if (getCookie('bi_first_login')) {
      try {
        await axiosInstance().post(process.env.REACT_APP_MIFTAH_API_URI + 'auth/confirmRegistration', {})
      } catch (error) {
        console.error(error)
      }
      eraseCookie('bi_first_login', process.env.REACT_APP_MAIN_DOMAIN)
    }
  }

  return (
    <div className="d-flex h-100 w-100 position-absolute justify-content-center align-items-center">
      <div className="spinner-border" role="status" style={{ color: '#3a2d83' }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

export default Auth
