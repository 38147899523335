import React from 'react'
import CSS from 'csstype'
import alertIcon from 'assets/img/picto/status-icon--info--electric-blue--without-border.svg'
import alertIconError from 'assets/img/picto/status-icon--error--without-border.svg'
import './Alert.scss'

type Props = {
  title: string,
  desc?: React.ReactNode | string,
  error?: boolean,
  action?: React.ReactNode,
  style?: CSS.Properties,
}

const Alert = ({ title, desc, error, action, style }: Props) => (
  <div className={`alert-message mt-3 d-flex ${error ? 'error' : 'info'}`} style={style}>
    <img src={error ? alertIconError : alertIcon} width="18" />
    <div className="ms-3">
      <p className="alert-header">{title}</p>
      <p className="alert-desc">{desc}</p>
      {action}
    </div>
  </div>
)

export default Alert
