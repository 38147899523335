import React from 'react'
import CSS from 'csstype'
import icon from 'assets/img/picto/icon-validation-status.svg'

type Props = {
  message: string,
  style: CSS.Properties,
}

const Error = ({ message, style }: Props) => (
  <div className="error-container" style={style}>
    <img src={icon} />
    {message}
  </div>
)

export default Error
